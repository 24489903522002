export const environment = {
  production: true,
  baseUrl: 'https://nartik-staging-back.ademo.net/api',

  googleClientID:'477391161953-ctfrmli5g7j3ml26kmoj2pdacv164gtu.apps.googleusercontent.com',
  googleSecret:'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',
  googleRedirectURl:'https://nartik-staging.ademo.net',

  FBAppID: '953936462339271',
  FBAppSecret:'939a06696e425f643eecdb782bc1ea07',

  stripeKey:'pk_test_51NywkpCewV8Cn5cnjxSrHYKwJMcDtlXGENYA4otlj3kYWH5sH3wLfKbjC39Lcr5047IfeRW0ZlW7PCfQxRkHzaLL00VlSlpR9X',

  apiKey_loqate:'WN85-CY69-AG78-ND97'

};
