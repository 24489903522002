import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { AuthService } from '../../services/auth/auth/auth.service';

@Injectable()
export class UserInterceptorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    const AuthToken = this.authService.getUserToken();

    const authRequest = req.clone({
      headers: req.headers.append('Authorization','Bearer ' + AuthToken),
    });
    return next.handle(authRequest);
  }
}
