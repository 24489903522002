import { Injectable, NgZone } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth/auth.service';

@Injectable()
export class ErrorCodesInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private notificationService: NotificationsService,
    private _auth: AuthService,
    private ngZone: NgZone,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (res) => {
            if (res instanceof HttpResponse) {
           if(res.body.identifier_code == 101001){                
                this.router.navigate(['/dashboard/overview']);
              }
              else  if(res.body.identifier_code == 101009){    
                  localStorage.setItem('subscribed_before','true')         
                  this.router.navigate(['/auth/sign-up/answer-question']);
              }
             
            }
        },
        (err) => {       
          if (err instanceof HttpErrorResponse) {
            if(err?.status == 401){
              this._auth.signOut();
              this.notificationService.error('', err?.error?.message);
            }

            //User account is not verified
            if(err?.error?.identifier_code==101005){
              this._auth.setUserObj(err?.error?.data?.user);
              this.router.navigate(["/auth/sign-up/authentication-step-two"]);
            }

            //User account is suspended
            if(err?.error?.identifier_code==101006){
              this._auth.signOut();
              this.notificationService.warn('', 'for more details please contact support');
              this.router.navigate(["/auth/sign-in"]);
            }

            //User address or birthday is missing
            if(err?.error?.identifier_code==101011){
              this._auth.setUserObj(err?.error?.data?.user)
              this._auth.setUserToken(err?.error?.data?.access_token)
              this.router.navigate(["/auth/sign-up/answer-question"]);
            }

            //in case user login in with unregistered account, we create a new account, 
            //so you should redirect the user to the rest of registration pages
            if(err?.error?.identifier_code==101007){
              this._auth.setUserObj(err?.error?.data?.user)
              this._auth.setUserToken(err?.error?.data?.access_token)
              this.router.navigate(["/auth/sign-up/answer-question"]);
            }
         
          }
        }
      )
    );
  }
}
