import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
    {path:'',redirectTo:'auth',pathMatch:'full'},
    {path:'auth',loadChildren:() => import('./modules/auth/auth.module').then(m => m.AuthModule)},
    {
        path:'dashboard',
        loadChildren:() => import('./modules/main-layout/main-layout.module').then(m => m.MainLayoutModule),
        canLoad:[AuthGuard]
    },
    {
        path:'my-account',
        loadChildren:() => import('./modules/my-account/my-account.module').then(m => m.MyAccountModule),
        canLoad:[AuthGuard]
    },
    {
        path:'articles',
        loadChildren:() => import('./modules/articles/articles.module').then(m => m.ArticlesModule),
        canLoad:[AuthGuard]
    },
    {path:'**',redirectTo:'dashboard',pathMatch:'full'},
];
@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top',anchorScrolling: 'enabled'})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
