import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canLoad(): boolean {
      this.authService.checkUserAuth();
      if(this.authService.isUserAuth()) {
        return true;
      } else {
        this.router.navigate(['/auth/sign-in']);
        return false;
      }
  }

  canActivate(): boolean{
    this.authService.checkUserAuth();
    if(this.authService.isUserAuth()) {
      return true;
    } else {
      this.router.navigate(['/auth/sign-in']);
      return false;
    }
  }
}
