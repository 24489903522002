import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'nartik';
  notifierOptions: any = {
    animate: "fromTop",
    timeOut: 5000,
    position: ["top", "right"],
    clickToClose: true,
    maxStack:1
  }
}
